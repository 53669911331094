import React from 'react';
import './footer.scss';

import { Link } from 'gatsby';
import { AppStateContext } from '../contexts/app-state-provider.context';
import { TranslatableText, TranslatedBlock } from './language';

import IMCLogoWhiteEN from 'src/images/IMC_logo_white_EN.png';
import IMCLogoWhiteFR from 'src/images/IMC_logo_white_FR.png';
import IMCLogoBlueEN from 'src/images/IMC_logo_blue_EN.png';
import IMCLogoBlueFR from 'src/images/IMC_logo_blue_FR.png';
import PAABLogoEN from 'src/images/PAAB_logo_color_EN.png';
import PAABLogoFR from 'src/images/PAAB_logo_color_FR.png';

export default class Footer extends React.Component {
  static contextType = AppStateContext;

  handleCookiePreferencesClick = (event) => {
    event.stopPropagation();
    window.OneTrust.ToggleInfoDisplay();
  };

  render() {
    let rpgCodeDict = null;
    const section = this.context.section;

    if (section === 'patient') {
      rpgCodeDict = {
        english: '29232E23v',
        french: '29232F23v',
      };
    } else if (section === 'hcp') {
      rpgCodeDict = {
        english: '29232E23v',
        french: '29232F23v',
      };
    }
    const rpgCode = rpgCodeDict ? (
      <div className="container rpg-code-container">
        <div className={'rpg-code'}>
          <TranslatableText dictionary={rpgCodeDict} />
        </div>
      </div>
    ) : null;

    return (
      <AppStateContext.Consumer>
        {({ highContrast }) => (
          <footer id="colophon" className="site-footer login-footer">
            <div className="site-info">
              <div className="container-fluid footer-container">
                <div className="container">
                  <Link to="/privacy-policy/" className="footer-link">
                    <TranslatableText
                      dictionary={{
                        english: 'Privacy policy',
                        french: 'Politique de confidentialité',
                      }}
                    />
                  </Link>
                  <Link to="/terms-and-conditions/" className="footer-link">
                    <TranslatableText
                      dictionary={{
                        english: 'Terms and Conditions',
                        french: "Conditions d'utilisation",
                      }}
                    />
                  </Link>
                  <button className="footer-link ot-sdk-show-settings" onClick={this.handleCookiePreferencesClick}>
                    <TranslatableText
                      dictionary={{
                        english: 'Cookie Preferences',
                        french: 'Préférences en matière de témoins',
                      }}
                    />
                  </button>
                </div>
              </div>
              <hr />
              <div className="container">
                <TranslatedBlock language="english">
                  <div className="aes-reporting-disclaimer">
                    <div className="resizable">
                      This website is neither intended nor designed to record or report adverse event information. If
                      you have a suspected side effect or problem to report regarding a Roche product, please email
                      mississauga.canada_medinfo@roche.com or call 1-888-762-4388. You can also report adverse events
                      relating to any product to Health Canada directly by going to the Canada Vigilance Program website
                      at{' '}
                      <a
                        className="footer-a-link"
                        href="https://www.canada.ca/en/health-canada/services/drugs-health-products/medeffect-canada/adverse-reaction-reporting.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.canada.ca/en/health-canada/services/drugs-health-products/medeffect-canada/adverse-reaction-reporting.html
                      </a>
                      .
                    </div>
                  </div>
                  <div className="additional-footer-info">
                    <div className="resizable"> Copyright © 2023, Hoffmann-La Roche Limited. All rights reserved.</div>
                    <div className="resizable">
                      {' '}
                      HEMLIBRA<sup>®</sup> Registered trade-mark of Chugai Seiyaku Kabushiki Kaisha, used under license
                    </div>
                  </div>
                  <div className="footer-logos">
                    <img src={PAABLogoEN} alt="PAAB logo" className={'paab-logo-img'} />
                    <img
                      src={highContrast ? IMCLogoBlueEN : IMCLogoWhiteEN}
                      alt="IMC logo"
                      className={'imc-logo-img'}
                    />
                  </div>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <div className="aes-reporting-disclaimer">
                    <div className="resizable">
                      Ce site Web n’est pas destiné à consigner ou à signaler des renseignements concernant des
                      manifestations indésirables et n’a pas été conçu à cet effet. Si vous souhaitez signaler un
                      problème ou un effet secondaire soupçonné lié à un produit de Roche, veuillez envoyer un courriel
                      à{' '}
                      <a className="footer-a-link" href="mailto:mississauga.canada_medinfo@roche.com">
                        mississauga.canada_medinfo@roche.com
                      </a>{' '}
                      ou composer le{' '}
                      <a className="footer-a-link" href="call:1-888-762-4388">
                        1-888-762-4388
                      </a>
                      . Vous pouvez également signaler des manifestations indésirables liées à un produit à Santé Canada
                      directement en vous rendant sur le site Web du programme Canada Vigilance à{' '}
                      <a
                        className="footer-a-link"
                        href="https://www.canada.ca/fr/sante-canada/services/medicaments-produits-sante/medeffet-canada/declaration-effets-indesirables.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.canada.ca/fr/sante-canada/services/medicaments-produits-sante/medeffet-canada/declaration-effets-indesirables.html
                      </a>
                      .
                    </div>
                  </div>
                  <div className="additional-footer-info">
                    <div className="resizable"> © 2023 Hoffmann-La Roche Limited/Limitée. Tous droits réservés.</div>
                    <div className="resizable">
                      {' '}
                      HEMLIBRA<sup>®</sup> Marque déposée de Chugai Seiyaku Kabushiki Kaisha, utilisée sous licence
                    </div>
                  </div>
                  <div className="footer-logos">
                    <img src={PAABLogoFR} alt="PAAB logo" className={'paab-logo-img'} />
                    <img
                      src={highContrast ? IMCLogoBlueFR : IMCLogoWhiteFR}
                      alt="IMC logo"
                      className={'imc-logo-img'}
                    />
                  </div>
                </TranslatedBlock>
              </div>
              {rpgCode}
            </div>
          </footer>
        )}
      </AppStateContext.Consumer>
    );
  }
}
